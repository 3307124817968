import * as React from 'react'
import { Link } from 'gatsby'

import Seo from '../components/seo'

const NotFoundPage = () => {
  return <header className='notFound'>
    <div className='col-lg-8'>
      <h1 className='text-white font-weight-bold'>404</h1>
      <h1 className='text-white font-weight-bold'>Upss :(</h1>
      <Link to='/' className='btn btn-primary btn-xl' style={{ marginTop: '1rem' }}>Volver a Inicio</Link>
    </div>
  </header>
}

export const Head = () => <Seo title='404' />

export default NotFoundPage

